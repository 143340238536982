import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import del from "../asset/img/del.png";
import edit from "../asset/img/edit2.png";
import {Helmet} from "react-helmet";
import "../Main/ipc.css"

export const MainTowx = () => {

  const [title, setTitle] = useState("");
  const [descs, setDescs] = useState("");
  const [main_id, setMainid] = useState("");

  const registerO = async () => {
    try {
      if (!title) {
        toast.info("لطفا تمامی موارد خواسته شده رو پر نمائید");
      } else {
        if (file1.length <= 0) {
          axios
            .post("https://drp.academyxray.com/panelAdmin/Mainxtow2", {
              title: title,
              main_id: main_id,
            })
            .then((res) => {
              toast.success("اطلاعات با موفقیت ثبت شد");
            })
            .catch((err) => {
              toast.error("خطا در عکس بارگذاری شده");
              console.log("Error :" + err);
            });
        } else {
          const formdata = new FormData();
          formdata.append("title", title);
          formdata.append("main_id", main_id);
          formdata.append("image", file1);
          axios
            .post("https://drp.academyxray.com/panelAdmin/Mainxtow", formdata)
            .then((res) => {
              toast.success("اطلاعات با موفقیت ثبت شد");
            })
            .catch((err) => {
              toast.error("خطا در عکس بارگذاری شده");
              console.log("Error :" + err);
            });
        }
      }
    } catch (e) {
      toast.info("امکان ثبت دوباره وجود ندارد");
    }
  };
 
  const [filed, setFiled] = useState([]);

  const handleDelete = (id) => {
    axios
      .delete(`https://drp.academyxray.com/panelAdmin/listDeletetow/${id}`)
      .then((response) => {
        setFiled((values) => {
          return values.filter((item) => item.id !== id);
        });
        toast.success("حذف  با موفقیت انجام شد");
      });
  };

  const [less, setless] = useState([]);

  useEffect(() => {
    const getAllFiled = async () => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/listMain");
      setless(await res.clone().json());
    };
    getAllFiled();
  }, [filed]);

useEffect(() =>{
  const listAll = async() =>{
  const res = await fetch(
    'https://drp.academyxray.com/panelAdmin/listMain2'
  );
  setFiled(await res.json());
  }
  listAll();
},[])

  const updateTwo = async (id) => {
    try {
      if (!title) {
        toast.warning("مقدار عنوان نباید خالی باشد");
      } else {
        axios
          .put(`https://drp.academyxray.com/panelAdmin/updateTwo/${id}`, {
            title: title,
          })
          .then((res) => {
            toast.success("ویرایش با موفقیت انجام شد");
          });
      }
    } catch (Err) {
      toast.error("خطا در ویرایش  - لطفا دوباره سعی کنید");
    }
  };

  const [file1, setfile1] = useState("");
  const [file2, setfile2] = useState("");
  const [file3, setfile3] = useState("");

  const handleFile1 = (e) => {
    setfile1(e.target.files[0]);
  };

  const handleFile2 = (e) => {
    setfile2(e.target.files[0]);
  };

  const handleFile3 = (e) => {
    setfile3(e.target.files[0]);
  };

  const updateImage1 = async () => {
    try {
      if (!file1) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image1", file1);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/update1/${1}`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
      }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };

  const updateImage2 = async () => {
    try {
      if (!file2) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image2", file2);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/update2/${1}`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
      }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };

  const updateImage3 = async () => {
    try {
      if (!file3) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image3", file3);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/update3/${1}`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
      }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };

  const [loading,setLoading] = useState([])
  useEffect(() => {
    const loadImage = async() => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/loadingImageTree");
      setLoading(await res.clone().json());
    }
    loadImage();
  },[])

const [search,setSearch] = useState('')
const [menr,setMenr] = useState('')

return (
    <>
    <Helmet>
      <title>زیر گروه اول</title>
    </Helmet>
      <body className="hold-transition sidebar-mini">
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="content-header pku3">
            <h4 className="pok">زیر گروه اول</h4>
            <hr />
            <div className="container-fluid">
              <div className="row mb-4">
            <div class="col-xs-12 col-sm-3 mt-3">
                <input
                style={{borderRadius:"20px"}}
                  type="file"
                  name="fileTwo"
                  className="form-control"
                  onChange={handleFile1}
                />
              </div>
              <img src='/img/upload.png' width={'40px'} height={'40px'} className="mt-3" style={{cursor:'pointer'}} onClick={updateImage3} />
              <div class="col-xs-12 col-sm-3 mt-3">
                <input
                style={{borderRadius:"20px"}}
                  type="file"
                  name="fileTwo"
                  className="form-control"
                  onChange={handleFile2}
                />
              </div>
              <img src='/img/upload.png' width={'40px'} height={'40px'} className="mt-3" style={{cursor:'pointer'}} onClick={updateImage2} />
              <div class="col-xs-12 col-sm-3 mt-3">
                <input
                style={{borderRadius:"20px"}}
                  type="file"
                  name="fileTwo"
                  className="form-control"
                  onChange={handleFile3}
                />
              </div>
              
            <img src='/img/upload.png' width={'40px'} height={'40px'} className="mt-3" style={{cursor:'pointer'}} onClick={updateImage1}/>
</div>
            </div>

              {filed.map((item, index) => {
                const { id } = item;
                return item.img != null ? (
                  <div class="col-xs-12 col-sm-5 mt-3">
                    {" "}
                   {/*  <img src={item.img} width={"100px"} height={"100px"} /> */}
                  </div>
                ) : null;
              })}
{loading.map((item) => {
        return(              
          
       <div className="container-fluid">
        <div className="row mb-4">
        <label className="pok mt-4" style={{marginTop:"10px", color:"green"}}>
                      اندازه پیشنهادی:<span style={{color:"red"}} className="pok">280*550</span>
        </label>
              <div class="col-xs-12 col-sm-1 mt-3">
                  <img src={item.img1} width={'50px'} height={'50px'} style={{borderRadius:"50%"}}/>
                  </div>
                   <div class="col-xs-12 col-sm-1 mt-3">
                  <img src={item.img2} width={'50px'} height={'50px'} style={{borderRadius:"50%"}}/>
                  </div>
                 <div class="col-xs-12 col-sm-1 mt-3">
                  <img src={item.img3} width={'50px'} height={'50px'} style={{borderRadius:"50%"}}/>
              </div>        
         </div>
         </div>
    )
  })
}
              <div className="container-fluid">
                <div className="row mb-2">
                  <div class="col-xs-12 col-sm-3 mt-3">
                    <select style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control pok"
                      onChange={(e) => setMainid(e.target.value)}
                      name="fieldID"
                    >
                      <option style={{borderRadius:"20px"}} className="pok" value={"23e0w"}>انتخاب کنید</option>
                      {less.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                      <option value={"all"}>All</option>
                    </select>
                  </div>

                  <div class="col-xs-12 col-sm-3 mt-3">
                    <input
                      type="text"
                      className="form-control pok"
                      placeholder="عنوان"
                      onChange={(e) => setTitle(e.target.value)}
                      style={{borderRadius:"20px"}}
                    />
                  </div>

                  <div class="col-xs-12 col-sm-3 mt-3">
                    <button style={{borderRadius:"20px"}} className="btn btn-outline-success pok" onClick={registerO}>
                      ایجاد کردن
                    </button>
                  </div>
                  
                </div>
              </div>
              <hr />
         <div class="table-responsive">
         <div className="container-fluid">
                <div className="row mb-2">
         <select style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control pok col-sm-3"
                      onChange={(e) => setMenr(e.target.value)}
                      name="menr"
                    >
                      <option style={{borderRadius:"20px"}} className="pok" value={"23e0w"}>جستجو گروه</option>
                      {less.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                      <option value={"all"}>All</option>
                    </select>
                        <p className="pok mt-2 mr-2"> جستجو براساس عنوان : </p>
               <input type="text" className="form-control col-sm-3 mt-1 mr-1 pok" onChange={(e) => setSearch(e.target.value)}/>
                </div>
                </div>
                <table class="table">
                  <thead>
                    <tr>
                    <th className="pok">گروه اصلی</th>
                    <th className="pok">عنوان</th>
                   <th className="pok">ویراش / حذف</th>
                    </tr>
                  </thead>
                  {filed 
                    .filter((item) => {
                     if(item.search !== null){
                      return search.toLowerCase() === ""
                        ? item
                        : item.title.toLowerCase().includes(search);
                     }
                     if(item.menr !== null){
                      return menr.toLowerCase() === ""
                      ? item
                      : item.main_id.toLowerCase().includes(menr);
                     }
                    })
                    
                 .map((item, index) => {
                    const { id } = item;
                    return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                          <td className="pok">{item.main_id}</td>
                          <td className="pok">{item.title}</td>
                          <td>
                            <img
                              src={edit}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              onClick={() => updateTwo(id)}
                            /> 
                        
                         <img
                              src={del}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDelete(id)}
                              className="mr-3"
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            
            </div>
          </div>
        </div>
      </body>

      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
