import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import del from "../asset/img/del.png";
import edit from "../asset/img/edit2.png";
import editA from "../asset/img/editA.png";
import {Helmet} from "react-helmet";

export const ToggleImg = () => {
  const [title, setTitle] = useState("");
  const [main_id, setMainid] = useState("");
  const [maintow_id, SetMaintow_id] = useState("");
  const [t_id, setT_id] = useState("");
  const [descs, setDescs] = useState("");

  const handleSubmit = async () => {
    try {

      axios
        .post("https://drp.academyxray.com/panelAdmin/Mainxtoggle",{
           title:title,
           t_id:t_id,
           main_id: main_id,
           maintow_id:maintow_id,
           descs:descs
        })
        .then((res) => {
          toast.success("اطلاعات با موفقیت ثبت شد");
        })
        .catch((err) => {
          toast.error("خطا در عکس بارگذاری شده");
          console.log("ERROR :" + err);
        });
    } catch (e) {
      toast.info("امکان ثبت دوباره وجود ندارد");
    }
  };

  const [mainList, setMainList] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch(
        "https://drp.academyxray.com/panelAdmin/listMaintoggle"
      );
      setMainList(await res.json());
    };
    getAllProductsRT();
  }, [mainList]); 
  
  const listDelete = (id) => {
    axios
      .delete(`https://drp.academyxray.com/panelAdmin/listDeletetoggle/${id}`)
      .then((response) => {
        setMainList((values) => {
          return values.filter((item) => item.id !== id);
        });
        toast.success("حذف  با موفقیت انجام شد");
      });
  };

  const [field, setfield] = useState([]);

  useEffect(() => {
    const getAllFiled = async () => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/listMain");
      setfield(await res.clone().json());
    };
    getAllFiled();
  }, []);

  const [found, setFound] = useState([]);

  const getItemTree = async (id) => {
    try {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/searchItemMainTow/${id}`
      );
      setFound(await res.json());
    } catch (err) {}
  };

  const [foundR, setFoundR] = useState([]);

  const getItemTree2 = async (id) => {
    try {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/searchItemMainlistsAB/${id}`
      );
      setFoundR(await res.json());
    } catch (err) {}
  };

  const getItemTreeAll = async (id) => {
    try {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/listMaintoggle2/${id}`
      );
      setMainList(await res.json());
    } catch (err) {}
  };

const [ide,setIde] = useState('');

const getideall = (idr) => {
  toast.success('ردیف انتخا شد : ' + idr)
  setIde('')
  setIde(idr)
  console.log(idr)
}

  const [file1, setfile1] = useState("");
  const [file2, setfile2] = useState("");
  const [file3, setfile3] = useState("");

  const handleFile1 = (e) => {
    setfile1(e.target.files[0]);
  };

  const handleFile2 = (e) => {
    setfile2(e.target.files[0]);
  };

  const handleFile3 = (e) => {
    setfile3(e.target.files[0]);
  };

  const updateImage1 = async () => {
    try {
      if(ide === null || ide === ''){
        toast.warning('هنوز ردیفی انتخاب نشده است')
      }else{
      if (!file1) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image1", file1);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/update1e/${ide}`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
      }
  
    }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };

  const updateImage2 = async () => {
    try {
      if(ide === null || ide === ''){
        toast.warning('هنوز ردیفی انتخاب نشده است')
      }else{
      if (!file2) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image2", file2);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/update2e/${ide}`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
      }
    }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };

  const updateImage3 = async () => {
    try {
      if(ide === null || ide === ''){
        toast.warning('هنوز ردیفی انتخاب نشده است')
      }else{
      if (!file3) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image3", file3);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/update3e/${ide}`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
       }
     }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };
  
  return (
    <>
   <Helmet>
      <title>محتوای دروس</title>
    </Helmet>
      <body className="hold-transition sidebar-mini">
        <div className="wrapper">
          <div className="content-wrapper pku5">
            <div className="content-header">
            <h4 className="pok">محتوای دروس</h4>
            <hr/>
            <div className="container-fluid">
              <div className="row mb-4">
            <div class="col-xs-12 col-sm-2 mt-3">
                <input
                style={{borderRadius:"20px"}}
                  type="file"
                  name="fileTwo"
                  className="form-control"
                  onChange={handleFile1}
                />
              </div>
              <p className="mt-4">Show Problem</p>
              <img src='/img/upload.png' width={'40px'} height={'40px'} className="mt-3 mr-2" style={{cursor:'pointer'}} onClick={updateImage3} />
              <div class="col-xs-12 col-sm-3 mt-3">
                <input
                style={{borderRadius:"20px"}}
                  type="file"
                  name="fileTwo"
                  className="form-control"
                  onChange={handleFile2}
                />
              </div>
              <p className="mt-4">X-RAY</p>
              <img src='/img/upload.png' width={'40px'} height={'40px'} className="mt-3 mr-2" style={{cursor:'pointer'}} onClick={updateImage2} />
              <div class="col-xs-12 col-sm-3 mt-3">
                <input
                style={{borderRadius:"20px"}}
                  type="file"
                  name="fileTwo"
                  className="form-control"
                  onChange={handleFile3}
                />
              </div>
              <p className="mt-4">REAL</p>
            <img src='/img/upload.png' width={'40px'} height={'40px'} className="mt-3 mr-3" style={{cursor:'pointer'}} onClick={updateImage1}/>
</div>
</div>
              <div className="container-fluid">
                <div className="row mb-2">
                  <div class="col-xs-12 col-sm-3 mt-3">
                    <select
                      id="selce"
                      className="form-control pok"
                      style={{borderRadius:"20px"}}
                      onChange={(e) => setMainid(e.target.value)}
                      onClick={() => getItemTree(main_id)}
                      name="main_id"
                    >
                      <option className="pok" value={"23e0w"}>انتخاب کنید</option>
                      {field.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                    </select>
                  </div>
                  <div class="col-xs-12 col-sm-3 mt-3">
                    <select
                    style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control pok"
                      onChange={(e) => SetMaintow_id(e.target.value)}
                      onClick={() => getItemTree2(maintow_id)}
                      name=""
                    >
                      <option className="pok" value={"0wss0"}>انتخاب کنید</option>
                      {found.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                    </select>
                  </div>

                  <div class="col-xs-12 col-sm-3 mt-3">
                    <select
                    style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control pok"
                      onChange={(e) => setT_id(e.target.value)}
                      onClick={() => getItemTreeAll(t_id)}
                      name="t_id"
                    >
                      <option className="pok" value={"0wss0"}>انتخاب کنید</option>
                      {foundR.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                    </select>
                  </div>
                  <div class="col-xs-12 col-sm-3 mt-3 pok">
                    <button className="btn btn-outline-success" style={{borderRadius:"20px"}} onClick={handleSubmit}>
                      ایجاد کردن
                    </button>
                  </div>
                  <div class="col-xs-12 col-sm-12 mt-3 pok">
                    <textarea
                    style={{borderRadius:"20px"}}
                      rows={5}
                      className="form-control"
                      placeholder="توضیحات"
                      onChange={(e) => setDescs(e.target.value)}
                    />
                  </div>
  
                </div>
              </div>
              <hr />
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th className="pok">انتخاب</th>
                      <th className="pok">ردیف</th>
                      <th className="pok">گروه اصلی</th>
                      <th className="pok">زیر گروه اول</th>
                      <th className="pok">زیر گروه دوم</th>
                      <th className="pok">تصویر</th>
                      <th className="pok">نمایش تست</th>
                      <th className="pok">ایجاد تست</th>
                      <th className="pok">ویرایش / حذف</th>
                    </tr>
                  </thead>
                  {mainList.map((item, index) => {
                    const { id, main_id, maintow_id, t_id } = item;
                    return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                          <td className="pok"> <img
                              src={editA}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              onClick={() => getideall(id)}
                            /></td>
                          <td className="pok">{index + 1}</td>
                          <td className="pok">{item.main_id}</td>
                          <td className="pok">{item.maintow_id}</td>
                          <td className="pok">{item.t_id}</td>
                          <td>
                            <img
                              src={item.img}
                              width={"30px"}
                              height={"30px"}
                            />
                       <img
                              src={item.img2}
                              width={"30px"}
                              height={"30px"}
                              className="mr-1"
                            />
                          <img
                              src={item.img3}
                              width={"30px"}
                              height={"30px"}
                              className="mr-1"
                            />
                          </td>
                          <td className="pok">
                           <img src="/img/ok.png" height={'25px'} width={'25px'} />
                           <img src="/img/no.png" height={'25px'} width={'25px'} className="mr-1" />
                           <img src="/img/no.png" height={'25px'} width={'25px'} className="mr-1" />
                          </td>
                          <td className="pok">
                <Link className="pok" to='/panelAdmin/Question' state={{ main_id, maintow_id,t_id }}>تست جدید</Link>
                          </td>
                          <td>
                            <img
                              src={edit}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              /*  onClick={() => listDelete(id)} */
                            />
                            <img
                              src={del}
                              height="30px"
                              width="30px"
                              className="mr-3"
                              style={{ cursor: "pointer" }}
                              onClick={() => listDelete(id)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </body>

      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
