import React from "react";
import { Link } from "react-router-dom";
import '../Dashboard/fontR.css'
import '../Main/ipc.css'
import {Helmet} from "react-helmet";

export const LoginAdmin = () => {

  return (
    <>
  <Helmet>
      <title>DrpAdmin</title>
    </Helmet>
      <body
        className="hold-transition login-page"
        style={{ backgroundColor: "white" }}
      >
        <div className="login-box pku1">
          <div className="login-logo">
            <a href="../../index2.html">
              <h2 className="pok">پنل مدیریت</h2>
            </a>
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg pok">نام کاربری و رمز را وارد نمائید</p>
              <form action="../../index2.html" method="post">
                <div className="input-group mb-3">
                  <input
                    className="form-control pok"
                    placeholder="ایمیل"
                    type="email"
                  />
                  <div className="input-group-append">
                    <span className="fa fa-envelope input-group-text" />
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    placeholder="رمز عبور"
                    type="password"
                  />
                  <div className="input-group-append">
                    <span className="fa fa-lock input-group-text" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <div className="checkbox icheck">
                      <label className="pok">
                        <input type="checkbox" className="pok" /> یاد آوری من
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <Link to={"/panelAdmin/Mainx"}>
                      <button className="btn btn-primary btn-block btn-flat pok">
                        ورود
                      </button>
                    </Link>
                  </div>
                </div>
              </form>

              <p className="mb-1 pok">
                <a href="#" className="pok">رمز عبورم را فراموش کرده ام.</a>
              </p>

           </div>
          </div>
        </div>
      </body>
    </>
  );
};
