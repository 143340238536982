import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate,useLocation } from "react-router-dom";
import del from "../asset/img/del.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { modules, formats } from "../util/EditorToolbar";
import edit from "../asset/img/edit2.png";
import {Helmet} from "react-helmet";

export const Question = () => {

  const [ques, setques] = useState();
  const [ques1, setques1] = useState("");
  const [ques2, setques2] = useState("");
  const [ques3, setques3] = useState("");
  const [ques4, setques4] = useState("");
  const [req, setreq] = useState("");
  const [hard, setHard] = useState("");
  const [trueq, settrueq] = useState("");
  const [num, setNum] = useState("");
  const [file1, setfile1] = useState("");
  const [main_id, setMainid] = useState("");
  const [maintow_id, SetMaintow_id] = useState("");
  const [t_id, setT_id] = useState("");
  var string = '';
  const [searchTerm, setSearchTerm] = useState("");
  const handleFile1 = (e) => {
    setfile1(e.target.files[0]);
  };

  const location = useLocation();
  const propsData = location.state;
  
  const handleSubmit = async () => {
    try {
      if(propsData === null){
        const formdata = new FormData();
        formdata.append("ques1", ques1);
        formdata.append("ques2", ques2);
        formdata.append("ques3", ques3);
        formdata.append("ques4", ques4);
        formdata.append("image", file1);
        formdata.append("type1", main_id );
        formdata.append("type2", maintow_id);
        formdata.append("type3", t_id);
        formdata.append("hard", hard);
        formdata.append("trueq", trueq);
        formdata.append("ques", ques);
        formdata.append("req", req);
        axios
          .post("https://drp.academyxray.com/panelAdmin/QuestionReg", formdata)
          .then((res) => {
            toast.success("اطلاعات با موفقیت ثبت شد");
          })
          .catch((err) => {
            toast.error("خطا در عکس بارگذاری شده");
          });
        }else{
          const formdata = new FormData();
          formdata.append("ques1", ques1);
          formdata.append("ques2", ques2);
          formdata.append("ques3", ques3);
          formdata.append("ques4", ques4);
          formdata.append("image", file1);
          formdata.append("type1",  propsData.main_id);
          formdata.append("type2",  propsData.maintow_id);
          formdata.append("type3",  propsData.t_id);
          formdata.append("hard", hard);
          formdata.append("trueq", trueq);
          formdata.append("ques", ques);
          formdata.append("req", req);
          axios
            .post("https://drp.academyxray.com/panelAdmin/QuestionReg", formdata)
            .then((res) => {
              toast.success("اطلاعات با موفقیت ثبت شد");
            })
            .catch((err) => {
              toast.error("خطا در عکس بارگذاری شده");
            });
        }
    } catch (e) {
      toast.info("امکان ثبت دوباره وجود ندارد");
    }
  };

  const [mainList, setMainList] = useState([]);
  const nav = useNavigate();

  /*   useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/listQuesion");
      setMainList(await res.json());
    };
    getAllProductsRT();
  }, [mainList]); */

  const getItemTreeBas = async (id) => {
    try {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/listQuesionCB/${id}`
      );
      setMainList(await res.json());
    } catch (err) {}
  };

  const listDelete = (id) => {
    axios
      .delete(`https://drp.academyxray.com/panelAdmin/listDeletetoggleRP/${id}`)
      .then((response) => {
        setMainList((values) => {
          return values.filter((item) => item.id !== id);
        });
        toast.success("حذف  با موفقیت انجام شد");
      });
  };

  const [field, setfield] = useState([]);

  useEffect(() => {
    const getAllFiled = async () => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/listMain");
      setfield(await res.clone().json());
    };
    getAllFiled();
  }, []);

  const [found, setFound] = useState([]);

  const getItemTree = async (id) => {
    try {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/searchItemMainTow/${id}`
      );
      setFound(await res.json());
    } catch (err) {}
  };

  const [foundR, setFoundR] = useState([]);

  const getItemTree2 = async (id) => {
    try {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/searchItemMainlistsAB/${id}`
      );
      setFoundR(await res.json());
    } catch (err) {}
  };

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  return (
    <>
   <Helmet>
      <title>مدیریت تست</title>
    </Helmet>
      <body className="hold-transition sidebar-mini">
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="content-header pku3 ">
            <h4 className="pok">مدیریت تست</h4>
            <hr />
              <div className="container-fluid">
              <div class="col-xs-12 col-sm-5 mt-3">
                <p className="pok">جستجو بر اساس  گروه اصلی</p>
                    <input                    
                      id="searchInput"
                      type="text"
                      className="form-control pok"
                      style={{ textAlign: "center" , borderRadius:"20px"}}
                      placeholder="جستجو بر اساس  - کد ملی "
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <hr />
                <div className="row mb-2">
                  <div class="col-xs-12 col-sm-3 mt-3">
                  {propsData === null ?
                    <select
                    style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control pok"
                      onChange={(e) => setMainid(e.target.value)}
                      onClick={() => getItemTree(main_id)}
                      name="main_id"
                    >
                      <option className="pok" value={"23e0w"}>زیر گروه اول</option>
                      {field.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                    </select>
                    :
                    <input type="text" className="form-control pok"  value={propsData.main_id} onChange={(e) => setMainid(e.target.value)}/>
                      }
                  </div>
                  <div class="col-xs-12 col-sm-3 mt-3">
                  {propsData === null ?
                    <select
                    style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control pok"
                      onChange={(e) => SetMaintow_id(e.target.value)}
                      onClick={() => getItemTree2(maintow_id)}
                      name=""
                    >
                      <option className="pok" value={"0wss0"}>زیر گروه دوم</option>
                      {found.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                    </select>
                    :
                      <input type="text" className="form-control pok"  value={propsData.maintow_id} onChange={(e) => SetMaintow_id(e.target.value)}/>
                  }
                  </div>

                  <div class="col-xs-12 col-sm-3 mt-3">
                  {propsData === null ?
                    <select
                    style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control pok"
                      onChange={(e) => setT_id(e.target.value)}
                      onClick={() => getItemTreeBas(t_id)}
                      name="t_id"
                    >
                      <option className="pok" value={"0wss0"}>عنوان درس</option>
                      {foundR.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                    </select>
                    :
                     propsData.title != null ?
                     <input type="text" className="form-control pok"  value={propsData.title} onChange={(e) => setT_id(e.target.value)}/>
                      :
                      <input type="text" className="form-control pok"  value={propsData.t_id} onChange={(e) => setT_id(e.target.value)}/>
                  }
                  </div>
                  <div class="col-xs-12 col-sm-3 mt-3">
                    <input
                    style={{borderRadius:"20px"}}
                      type="file"
                      name="file1"
                      className="form-control"
                      onChange={handleFile1}
                    />
                  </div>
                  <div class="col-xs-12 col-sm-3 mt-3">
                    <select
                    style={{borderRadius:"20px"}}
                      id="selce"
                      onChange={(e) => setHard(e.target.value)}
                      className="form-control pok"
                      name="unitRF"
                    >
                      <option className="pok" value={"آسان"}>درجه سختی سوال</option>
                      <option className="pok" value={"آسان"}>آسان</option>
                      <option className="pok" value={"متوسط"}>متوسط</option>
                      <option className="pok" value={"سخت"}>سخت</option>
                    </select>
                  </div>
                  <div class="col-xs-12 col-sm-3 mt-3">
                    <button className="btn btn-outline-success pok" style={{borderRadius:"20px"}} onClick={handleSubmit}>
                      ایجاد کردن
                    </button>
                  </div>
                </div>
              </div>
              <div className="text-editor" >
                <EditorToolbar />
                <ReactQuill
                  className="whitespace-pre-line"
                  theme="snow"
                  onChange={setques}
                  placeholder="Type Question"
                  modules={modules}
                  formats={formats}
                  style={{ backgroundColor: "white", height: "100px" }}
                />
              </div>
  {/*             <textarea
                className="form-control mt-3"
                dir="ltr"
                rows={5}
                placeholder="Answer"
                onChange={(e) => setreq(e.target.value)}
              ></textarea> */}
              <div className="container mt-3">
                
                  <div class="col-xs-12 col-sm-8 mt-2">
                    <h6 className="pok">
                      گزینه اول
                      <input
                        className="form-control mt-2"
                        style={{borderRadius:"20px"}}
                        type="text"
                        onChange={(e) => setques1(e.target.value)}
                      />
                    </h6>
                  </div>
                  <div class="col-xs-12 col-sm-8 mt-2">
                    <h6 className="pok">
                      گزینه دوم
                      <input
                        className="form-control mt-2"
                        style={{borderRadius:"20px"}}
                        type="text"
                        onChange={(e) => setques2(e.target.value)}
                      />
                    </h6>
                  </div>
                  <div class="col-xs-12 col-sm-8 mt-2">
                    <h6 className="pok">
                      گزینه سوم
                      <input
                      style={{borderRadius:"20px"}}
                        className="form-control mt-2 pok"
                        type="text"
                        onChange={(e) => setques3(e.target.value)}
                      />
                    </h6>
                  </div>
                  <div class="col-xs-12 col-sm-8 mt-2">
                    <h6 className="pok">
                      گزین چهارم
                      <input
                      style={{borderRadius:"20px"}}
                        className="form-control mt-2 pok"
                        type="text"
                        onChange={(e) => setques4(e.target.value)}
                      />
                    </h6>
                  </div>
                  <div class="col-xs-12 col-sm-3 mt-2">
                    <select
                    style={{borderRadius:"20px"}}
                      id="selce"
                      onChange={(e) => settrueq(e.target.value)}
                      className="form-control mt-4 pok"
                      name="unitRF"
                    >
                      <option className="pok" value={"انتخاب نشده"}>پاسخ کلیدی</option>
                      <option className="pok" value={"ques1"}>گزینه یک</option>
                      <option className="pok" value={"ques2"}>گزینه دو</option>
                      <option className="pok" value={"ques3"}>گزینه سه</option>
                      <option className="pok" value={"ques4"}>گزینه چهار</option>
                    </select>
                  </div>
              
              </div>
              <hr />
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th className="pok">ردیف</th>
                      <th className="pok">گروه اصلی</th>
                      <th className="pok">زیر گروه اول</th>
                      <th className="pok">زیر گروه دوم</th>
                      <th className="pok">تصویر</th>
                      <th className="pok">سوال</th>
                      <th className="pok">درجه سختی</th>
                      <th className="pok">ویرایش</th>
                      <th className="pok">حذف</th>
                    </tr>
                  </thead>


                  {mainList
                  .filter((item) => {
                    return searchTerm.toLowerCase() === ""
                      ? item
                      : item.type1.toLowerCase().includes(searchTerm);
                  })
                  .map((item, index) => {
                    const { id,ques } = item;
                  return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                          <td className="pok">{index + 1}</td>
                          <td className="pok">{item.type1}</td>
                          <td className="pok">{item.type2}</td>
                          <td className="pok">{item.type3}</td>                                                 
                          <td>
                            <img
                              src={item.img}
                              width={"80px"}
                              height={"50px"}
                            />
                          </td>
                          <td className="pok">{getText(item.ques)}</td>
                          <td className="pok">{item.hard}</td>
                          <td>
                            <img
                              src={edit}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                          <td>
                            <img
                              src={del}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              onClick={() => listDelete(id)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </body>

      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default Question;
