import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import del from "../asset/img/del.png";
import edit from "../asset/img/edit2.png";
import {Helmet} from "react-helmet";

export const Mainx = () => {

  const [title, setTitle] = useState("");
  const [file1, setfile1] = useState("");

  const handleFile1 = (e) => {
    setfile1(e.target.files[0]);
  };

  const handleSubmit = async () => {
    try {
      if (!title) {
        toast.info("لطفا تمامی موارد خواسته شده رو پر نمائید");
      } else {
        const formdata = new FormData();
        formdata.append("title", title);
        formdata.append("image", file1);
        axios
          .post("https://drp.academyxray.com/panelAdmin/Mainx", formdata)
          .then((res) => {
            toast.success("اطلاعات با موفقیت ثبت شد");
          })
          .catch((err) => {
            toast.error("خطا در عکس بارگذاری شده");
          });
      }
    } catch (e) {
      toast.info("امکان ثبت دوباره وجود ندارد");
    }
  };

  const [mainList, setMainList] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/listMain");
      setMainList(await res.json());
    };
    getAllProductsRT();
  }, [mainList]);

  const listDelete = (id) => {
    axios
      .delete(`https://drp.academyxray.com/panelAdmin/listDelete/${id}`)
      .then((response) => {
        setMainList((values) => {
          return values.filter((item) => item.id !== id);
        });
        toast.success("حذف  با موفقیت انجام شد");
      });
  };

  const updateImage = async (id) => {
    try {
      if (!file1) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image", file1);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/update/${id}`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
      }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };

 const editImage = async() => {
  toast.success("سایز عکس بک گراند صحیح نمی باشد")
 }

return (
    
<div>
    <Helmet>
      <title>صفحه اصلی برنامه</title>
    </Helmet>
      <body className="hold-transition sidebar-mini">
      
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="content-header pku">
              <div className="container-fluid">
              <h4 className="pok">صفحه اصلی برنامه</h4>
              <hr />
                <div className="row mb-2">
                  <div class="col-xs-12 col-sm-3 mt-3">
                    <input
                      type="file"
                      style={{borderRadius:"20px"}}
                      name="file1"
                      className="form-control"
                      onChange={handleFile1}
                    />
                  </div>
                  <div class="col-xs-12 col-sm-3 mt-3">
                    <label className="pok" style={{marginTop:"10px", color:"green"}}>
                      اندازه پیشنهادی:<span style={{color:"red"}} className="pok mt-5">290*550</span>
                    </label>
                  </div>
                  <div class="col-xs-12 col-sm-3 mt-3">
                  <img src="/img/bg1.62799f21.png" width={'60px'} height={'60px'} style={{borderRadius:"50%"}}/>
                  </div>
                  <div class="col-xs-12 col-sm-3 mt-3">
         {/*            <button className="btn btn-success" onClick={handleSubmit}>
                      ایجاد کردن
                    </button> */}
                    <button style={{borderRadius:"20px"}} className="btn btn-outline-success pok" onClick={editImage}>
                       ویرایش عکس
                    </button>
                  </div>


                </div>
              </div>
              <br />
              
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr className="pok">
                      <th className="pok">ردیف</th>
                      <th className="pok">عنوان</th>
                      <th className="pok">تصویر</th>
                      <th className="pok">سایز</th>
                      <th className="pok">ویرایش/حذف</th>
                    </tr>
                  </thead>
                  {mainList.map((item, index) => {
                    const { id, title, img } = item;
                    return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                          <td className="pok">{index + 1}</td>
                          <td className="pok">{item.title}</td>
                          <td>
                            <img
                              src={item.img}
                              width={"80px"}
                              height={"50px"}
                            />
                          </td>
                          <td className="pok">
                          {item.siz}
                          </td>
                          <td>
                            <img
                              src={edit}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              onClick={() => updateImage(id)}
                            />
                    <img
                              src={del}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              onClick={() => listDelete(id)}
                              className="mr-3"
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </body>

      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};
